import React from "react";
import * as PropTypes from "prop-types";
import UserSession, {signOut} from "../../data/UserSession";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography} from "@material-ui/core";
import FormGrid from "../../components/FormGrid";
import LoadingModal from "../../components/loaders/LoadingModal";
import {red} from "@material-ui/core/colors";
import {withTranslation} from "react-i18next";
import { IdPortenMethod } from "../../data/dtos/Global";
import { isApp, isIOS } from "../../common/PWA";

const LoginDialog = React.lazy(() => import( "./LoginDialog"));

@withTranslation('authentication')
class AuthenticationWidget extends React.PureComponent {
    static propTypes = {
        homeUrl: PropTypes.string,
        onAuthenticated: PropTypes.func,
    };

    state = {
        userSession: UserSession.cacheOnly(),
    };

    handleForeginerLogin = () => {
        const {i18n} = this.props;
        i18n.changeLanguage('en');

        this.setState({
            modal: <React.Suspense fallback={<LoadingModal/>}>
                <LoginDialog
                    allowRegistration
                    onClose={() => this.setState({modal: null})}
                    onAuthenticated={this.props.onAuthenticated}
                />
            </React.Suspense>
        });
    };

    handleUsernamePasswordLogin = () => this.setState({
        modal: <React.Suspense fallback={<LoadingModal/>}>
            <LoginDialog
                onClose={() => this.setState({modal: null})}
                onAuthenticated={this.props.onAuthenticated}
            />
        </React.Suspense>
    });

    render(): React.ReactNode {
        const {t, homeUrl, onAuthenticated} = this.props;
        const {modal, userSession} = this.state;

        if (userSession?.user) {
            return <div style={{padding: '50px'}}>
                <FormGrid>
                    <img
                        src={'/img/logo/icon-text.png'}
                        style={{height: '38px'}}
                        alt={'DKS Logo'}
                    />

                    <div style={{height: '24px'}}/>

                    {homeUrl
                        ? <>
                            <Typography variant={"h5"}>
                                Logg inn
                            </Typography>
                            <Typography variant={"subheading"}>
                                til <Link to={homeUrl} color={"primary"}>{homeUrl}</Link>
                            </Typography>
                        </>
                        : <>
                            <Typography variant={"h5"}>
                                {t('welcome')}
                            </Typography>
                        </>}

                    <div style={{height: '16px'}}/>

                    <Button
                        variant={"contained"}
                        color={"primary"}
                        style={{textTransform: 'none'}}
                        onClick={onAuthenticated}
                    >
                        Fortsett som {userSession.user.displayName}
                    </Button>

                    <Button
                        variant={"outlined"}
                        color={"primary"}
                        style={{textTransform: 'none'}}
                        onClick={this.handleSignOut}
                    >
                        Bytt bruker
                    </Button>
                </FormGrid>
                {modal}
            </div>
        }

        const idportenAuthUrl = window.ENV_VARS && IdPortenMethod.SAML == window.ENV_VARS.idportenMethod 
            ? '/auth/saml/auth?idp=idporten.difi.no-v4&returnTo=' + encodeURIComponent(window.location.search)
            : `/auth/oidc/auth?${isApp() ? `pwa=${isIOS() ? 'ios' : 'android'}&` : ''}returnTo=${encodeURIComponent(window.location.search)}`;

        return <div style={{padding: '50px'}}>
            <FormGrid>
                <img
                    src={'/img/logo/icon-text.png'}
                    style={{height: '38px'}}
                    alt={'DKS Logo'}
                />

                <div style={{height: '24px'}}/>

                {homeUrl
                    ? <>
                        <Typography variant={"h5"}>
                            Logg inn
                        </Typography>
                        <Typography variant={"subheading"}>
                            til <Link to={homeUrl} color={"primary"}>{homeUrl}</Link>
                        </Typography>
                    </>
                    : <>
                        <Typography variant={"h5"}>
                            {t('welcome')}
                        </Typography>
                    </>}

                <div style={{height: '16px'}}/>

                <Typography variant={"h6"}>
                    {t('select_login_method')}
                </Typography>

                <Button
                    variant={"contained"}
                    color={"primary"}
                    component={'a'}
                    href={idportenAuthUrl}
                >
                    ID-porten
                </Button>

                {window.SSO_FEIDE && <Button
                    variant={"contained"}
                    color={"primary"}
                    component={'a'}
                    href={`/auth/saml/auth?${isApp() ? `pwa=${isIOS() ? 'ios' : 'android'}&` : ''}idp=${encodeURIComponent('https://idp.feide.no')}&returnTo=${encodeURIComponent(window.location.search)}`}
                >
                    {t('feide_login_title')}
                </Button>}

                <Button
                    variant={"outlined"}
                    color={"primary"}
                    onClick={this.handleForeginerLogin}
                >
                    I do not have a Norwegian social security number or D-number
                </Button>

                {/* <Button
                    onClick={this.handleUsernamePasswordLogin}
                >
                    {t('admin_login_method')}
                </Button> */}
            </FormGrid>

            {modal}
        </div>
    }

    async componentDidMount(): void {
        this.setState({userSession: await UserSession.networkOnly()});

        if (window.APP_ENV !== undefined && window.APP_ENV !== 'prod' && !localStorage.getItem('PRODWARNING')) {
            this.setState({
                modal: <Dialog open PaperProps={{style: {background: red[500], color: 'white'}}}>
                    <DialogTitle>Dette er testmiljø</DialogTitle>
                    <DialogContent>
                        Dette er testmiljø. Endringer her blir ikke lagret. Gå til <a
                        href={'https://portal.denkulturelleskolesekkn.no'}>portal.denkulturelleskolesekken.no</a>
                        for produksjonsmiljø.
                    </DialogContent>
                    <DialogActions>
                        <Button color={"inherit"} onClick={() => {
                            this.setState({modal: null});
                            localStorage.setItem('PRODWARNING', 'done');
                        }}>
                            Jeg forstår
                        </Button>
                    </DialogActions>
                </Dialog>
            });
        }
    }

    handleSignOut = async () => {
        this.setState({modal: <LoadingModal/>});
        await signOut();
        this.setState({userSession: await UserSession.networkOnly()});
        this.setState({modal: null});
    }
}

export default AuthenticationWidget;